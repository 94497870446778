























































import BaseDialog from "@/components/Base/BaseDialog.vue";
import { toCurrency } from "@/helpers/Formatter";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {
        BaseDialog,
    },
})
export default class SharedProposalGiveFundConfirm extends Vue {
    @Prop() value!: boolean;
    @Prop() loading!: boolean;
    @Prop() givenFund!: number;

    get showConfirm() {
        return this.value;
    }
    set showConfirm(isActive: boolean) {
        this.$emit("input", isActive);
    }

    toCurrency(value: number) {
        return toCurrency(value);
    }

    @Emit("submit")
    submit() {
        return this.givenFund;
    }
}
