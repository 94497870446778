



















































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import BaseDialog from "@/components/Base/BaseDialog.vue";
import BaseCard from "@/components/Base/BaseCard.vue";
import { toCurrency, toNumber } from "@/helpers/Formatter";
import InputCurrency from "@/components/Input/InputCurrency.vue";
import BaseAvatar from "@/components/Base/BaseAvatar.vue";

@Component({
    components: {
        BaseDialog,
        BaseCard,
        BaseAvatar,
        InputCurrency,
    },
})
export default class SharedProposalGiveFundDialog extends Vue {
    @Prop() value!: boolean;
    @Prop() proposal!: any;
    @Prop() maxGivenFund!: number;

    get form() {
        let form: any = {
            text: "Dana yang diberikan",
            placeholder: "Dana yang ingin diberikan",
            type: "currency",
            value: "given_fund",
            prefix: "Rp",
            max: this.maxGivenFund,
            withLabel: true,
        };

        return form;
    }

    public formData: any = {};

    get showDialog() {
        return this.value;
    }
    set showDialog(isActive: boolean) {
        this.$emit("input", isActive);
    }

    toCurrency(value: number) {
        return toCurrency(value);
    }

    get receivedFund() {
        let receivedFund: number = 0;

        if (
            this.proposal &&
            this.proposal.funds &&
            this.proposal.funds.length
        ) {
            this.proposal.funds.map((fund: any) => {
                receivedFund += fund.nominal;
            });
        }

        return receivedFund;
    }

    get restRequiredFund() {
        let restRequiredFund: number = 0;

        if (this.proposal && this.proposal.required_fund)
            restRequiredFund = this.proposal.required_fund;

        if (this.proposal && this.proposal.funds && this.proposal.funds.length)
            restRequiredFund = this.proposal.required_fund - this.receivedFund;

        return restRequiredFund;
    }

    @Emit("submit")
    confirmGiveFund() {
        let givenFund: number = toNumber(this.formData[this.form.value]);
        this.formData = {};
        this.formData = {};
        return givenFund;
    }
}
