























































































import { Component, Prop, Vue } from "vue-property-decorator";
import BaseCard from "@/components/Base/BaseCard.vue";
import BaseListTwoColumn from "@/components/Base/BaseListTwoColumn.vue";
import BaseAvatar from "@/components/Base/BaseAvatar.vue";
import BaseLink from "@/components/Base/BaseLink.vue";
import InputCurrency from "@/components/Input/InputCurrency.vue";
import SharedProposalGiveFundDialog from "./GiveFundDialog.vue";
import SharedProposalGiveFundConfirm from "./GiveFundConfirm.vue";
import CardsService from "@/services/CardsService";

@Component({
    components: {
        BaseCard,
        BaseListTwoColumn,
        BaseAvatar,
        BaseLink,
        InputCurrency,
        SharedProposalGiveFundDialog,
        SharedProposalGiveFundConfirm,
    },
})
export default class SharedProposalInfoProposerAndProposal extends Vue {
    @Prop() proposal!: any;
    @Prop() plan!: any;

    public showDialog: boolean = false;
    public showConfirm: boolean = false;
    public givenFund: number = 0;
    public isLoading: boolean = false;
    public userCard: any = null;

    get me() {
        return this.$store.state.users.me;
    }

    get showGiveFundButton() {
        if (!this.plan) return false;

        const fund = this.plan.fund;

        const hasFund = fund && fund > 0;
        const fundIsFulfilled = this.restRequiredFund === 0;
        const showGiveFundButton = hasFund && !fundIsFulfilled;

        return showGiveFundButton;
    }

    get showAddGalleryButton() {
        const isAlreadyGiveFund = this.proposal.funds.filter((fund: any) => {
            return fund.user.id === this.me.id;
        });

        return isAlreadyGiveFund.length;
    }

    get proposerInfo() {
        let proposerInfo: any[] = [];

        if (!this.proposal) return proposerInfo;

        proposerInfo = [
            {
                text: "Kontak",
                value: this.proposal.user.profile.phone_number,
                type: "text",
            },
            {
                text: "Bergabung",
                value: this.proposal.user.created_at,
                type: "date",
            },
        ];

        if (this.userCard) {
            const { bank_name, account_number, owner_name } = this.userCard;
            let cardDetail = `${bank_name} - ${account_number} a.n. ${owner_name}`;

            let card = {
                text: "Rekening",
                value: cardDetail,
                type: "text",
            };

            proposerInfo = [...proposerInfo, card];
        }

        return proposerInfo;
    }

    get proposalInfo() {
        let proposalInfo: any[] = [];

        if (!this.proposal) return proposalInfo;

        proposalInfo = [
            {
                text: "Dilihat",
                value: (this.proposal.seens.length || 0) + " perusahaan",
                type: "number",
            },
            {
                text: "Expired",
                value: this.proposal.expired_at || "-",
                type: "date",
            },
            {
                text: "Dana Yang Diperlukan",
                value: this.proposal.required_fund,
                type: "currency",
            },
            { text: "Dana Masuk", value: this.receivedFund, type: "currency" },
            {
                text: "Dana Belum Terpenuhi",
                value: this.restRequiredFund,
                type: "currency",
                valueClass: "font-weight-bold",
            },
        ];

        return proposalInfo;
    }

    get receivedFund() {
        let receivedFund: number = 0;

        if (
            this.proposal &&
            this.proposal.funds &&
            this.proposal.funds.length
        ) {
            this.proposal.funds.map((fund: any) => {
                receivedFund += fund.nominal;
            });
        }

        return receivedFund;
    }

    get restRequiredFund() {
        let restRequiredFund: number = 0;

        if (this.proposal && this.proposal.required_fund)
            restRequiredFund = this.proposal.required_fund;

        if (this.proposal && this.proposal.funds && this.proposal.funds.length)
            restRequiredFund = this.proposal.required_fund - this.receivedFund;

        return restRequiredFund;
    }

    get maxGivenFund() {
        if (!this.plan) return 0;

        const fund: number = this.plan.fund;
        const restRequiredFund: number = this.restRequiredFund;
        let maxGivenFund: number = restRequiredFund;

        if (fund < restRequiredFund) maxGivenFund = fund;

        return maxGivenFund;
    }

    openDialog() {
        this.showDialog = true;
    }

    closeDialog() {
        this.showDialog = false;
    }

    confirmGiveFund(givenFund: number) {
        this.givenFund = givenFund;
        this.showDialog = false;
        this.showConfirm = true;
    }

    async onGiveFund(givenFund: number) {
        console.log(givenFund);
        this.isLoading = true;

        const payload = {
            proposalId: this.proposal.id,
            givenFund: this.givenFund,
        };
        await this.$store.dispatch("proposals/addFund", payload);

        this.givenFund = 0;
        this.showConfirm = false;
        this.isLoading = false;
    }

    async getUserCard() {
        if (!this.proposal) return;

        let userId = this.proposal.user.id;
        let response = await CardsService.getUserCards(userId);
        let userHasCard =
            response && response.data && response.data.cards.length;

        if (userHasCard) this.userCard = response.data.cards[0];
    }

    mounted() {
        this.getUserCard();
    }
}
